import { Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  Headers,
  contractAddress,
} from "../../utils/constant";
import Button from "../ButtonLoading";
import Shimmer from "../Shimmer";

const ChargerCard = ({ index, Pay }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [investment, setInvestment] = useState({
    name: "",
    company: "",
    location: "",
    funds_filled: 0,
    funds_left: 0,
    investors: 0,
  });
  // const {myInvestments, setMyInvestments} = UserAuth();

  const getInvestment = async () => {
    setIsLoading(true);
    const deployedAbi = [
      `function returnKioskData(
            uint256 _id
        )
            external
            view
            returns (
                address kioskOwner,
                string memory kioskName,
                string memory kioskCompany,
                string memory kioskLocation,
                address energyProvider,
                address[] memory acceptedCurrencies,
                uint256 tranchePrice
            )`,
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    const deployedContract = new ethers.Contract(
      contractAddress,
      deployedAbi,
      provider
    );
    const kioskInformation = await deployedContract.returnKioskData(index);
    const investDetails = {
      name: kioskInformation[1],
      company: kioskInformation[2],
      location: kioskInformation[3],
    };
    setInvestment(investDetails);
    setIsLoading(false);
  };

  useEffect(() => {
    getInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Shimmer />
      ) : (
        <Card
          className="mt-10 ml-6 mr-4 p-4"
          style={{
            backgroundImage:
              "linear-gradient(137deg, #fff 14%, rgba(212, 248, 255, 0.79) 95%)",
            borderRadius: "12px",
            boxShadow: "0 0 18px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <div className="justify-between flex flex-row">
              <span className="text-lg font-bold">{investment.name}</span>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex flex-col mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Location</span>
              </div>
              <div>
                <span className="text-xs">{investment.location}</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex flex-col mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Company</span>
              </div>
              <div>
                <span className="text-xs">{investment.company}</span>
              </div>
            </div>
            <Button
              onClick={() => Pay(index)}
              className="w-28 font-medium text-md px-3 py-3 rounded-full text-white bg-cyan-600"
              text={"Pay"}
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default ChargerCard;
