
export const ROLE = {
  ADMIN: "ADMIN",
  ORGANIZATION: "ORGANIZATION",
  USER: "USER"
};

export const AdminAddresses = [
  "0x4b3fe39605023b7e75cb1fa6634da7dfa711c300",
  "0x82786e80e3f34e2eddac7a41b3338d8f995240ef",
  "0xec665a69b7c88427431f9c0ef96e425dfe99ec1b",
  "0x9cebbfc22e9faf7d680dbe2d5a64c2c885129a9a",
  "0xf7dbeb784d021f938eb598a6a586adf87c44937b",
  "0x3117e94de054a002fc552aef579c982bd693f609"
]

export const OrgAddresses = [
  "0xddc88213387c7bb1ac7bdeb59c5e041a2db7560f",
  "0xac9804d44cb426fbb7a719909dffb918165307ad",
  "0xc41e235b56504a697842c73149c7786a4c0c047f",
  "0xd6a02b0f7482023ec95118d3d843b8b09c4f2d5e",
  "0x3a4111c739e88f46cd29e39c357124dee680c52a",
  "0x3117e94de054a002fc552aef579c982bd693f609"
]

export const UserAddresses = [
  "0xbb389c09a1d53650e9f5cfe31d075e61b9fcb95f",
  "0x1123e2baa18bbecbbb9d4d5d612aaf4673e9306f",
  "0xa02d199506ae2850aad893bdb5a27a69514558d6",
  "0x88b5af0c54926bd66a31f96ba89fe56b6ab00750",
  "0x2ce2a27c7515b6a2a157a8358694065dca14725e",
]

export const Headers = {
  username: 'u0kkafl6lv',
  password: 'RFjad7ixBHMkY1-FGYrtWP32iii-neggaPS6vI3HQ7M'
};

export const contractAddress = '0x86338b7D3aA8e7bCcdFD15D6eeeb3fef69f2400A';

export const tokenAddress = '0xD22De4176c3088f93527A6bd4631eb95729D91e2';

export const userTokenAddress = '0x821BBb5d3dCFF9b71409e262b7C9E82a94675CDa';

export const lotSize = 10;