/* eslint-disable react/style-prop-object */
import { UserAuth } from "../../context/AuthContext";
import { useState } from "react";
import { ethers } from "ethers";
import { Card } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Chevron from "../../assets/Chevron.png";
import toast from "react-hot-toast";
import { Headers, contractAddress } from "../../utils/constant";
import SuccessScreen from "../common/SuccessScreen";

// const payoutOptions = [{ value: "Weekly", label: "Weekly" }];

const EditEv = () => {
  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  const [orgName, setOrgName] = useState("");
  const [name, setName] = useState("");
  const [investmentNeeded, setInvestmentNeeded] = useState("");
  const [location, setLocation] = useState("");
  // Disabled to Demo Purpose
  // const [size, setSize] = useState();
  // const [payout, setPayout] = useState();
  const [done, setDone] = useState(false);
	const [txHash, setTxHash] = useState();

  // const sizeOptions = [{ value: lotSize, label: "$100,000" }];

	const goToOrgLogin = async () => {
		await logout();
		navigate('/org/login');
	}

  const goBack = async () => {
    await logout();
    navigate("/admin/login");
  };

  const handleSubmit = async () => {
    try {
      if (
        !orgName ||
        !name ||
        !investmentNeeded ||
        !location
      ) {
        toast.error("Please fill all the details");
    		return;
      }
      const abi = [
        `function createKiosk(
    			string memory _kioskName,
    			string memory _kioskCompany,
    			string memory _kioskLocation,
    			address[] _paymentTokens,
    			uint256 _tranchePrice,
    			address _energyProvider
    	) returns (uint256)`,
        "function kiosks(uint256) public view returns (address)",
      ];

      const PrivateUrl = new ethers.FetchRequest(
        "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
      );
      PrivateUrl.setHeader(
        "Authorization",
        "Basic " +
          Buffer.from(Headers.username + ":" + Headers.password).toString(
            "base64"
          )
      );
      const provider = new ethers.JsonRpcProvider(PrivateUrl);
      const factoryContract = new ethers.Contract(
        contractAddress,
        abi,
        provider
      );
      const data = factoryContract.interface.encodeFunctionData("createKiosk", [
        name,
    		orgName,
    		location,
        [],
        ethers.parseEther(Number(investmentNeeded).toFixed(0)),
        user.address,
      ]);
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address, // The user's active address.
              to: contractAddress, // Required except during contract publications.
              data: data,
              gasPrice: 0,
            },
          ],
        })
        .then((txHash) => {
          console.log(txHash);
					setTxHash(txHash);
          toast.success("Transaction Successful");
					setDone(true);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {done === false ? (
        <>
          <div className="mt-4 ml-4 mb-4 flex flex-row items-center">
            <div
              className="align-middle cursor-pointer flex flex-row mt-4"
              onClick={goBack}
            >
              <div>
                <img src={Chevron} alt="Back" width={24} height={24} />
              </div>
              <div>
                <span className="text-md text-gray-600 mr-4">Back</span>
              </div>
            </div>
            <div className="justify-center align-middle items-center mt-4 ml-24 mr-6">
              <span className="text-lg font-bold text-gray-600 cursor-pointer items-center justify-center">
                EV set up
              </span>
            </div>
          </div>

          <div className="mt-6">
            <Card
              className="mt-4 ml-4 mr-4 p-4"
              style={{
                backgroundImage:
                  "linear-gradient(137deg, #fff 14%, rgba(212, 248, 255, 0.79) 95%)",
                borderRadius: "12px",
                boxShadow: "0 0 18px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="mt-6 mb-6">
                <div className="flex flex-col ml-2 mt-4">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 1,
                      backgroundColor: "white",
                      width: "8rem",
                      color: "#676767",
                    }}
                  >
                    Organization Name
                  </span>
                  <input
                    className="w-full form-input border p-4 rounded-md"
                    style={{ borderColor: "#676767" }}
                    type="text"
                    value={orgName}
                    placeholder="e.g. InstaVolt"
                    onChange={(event) => setOrgName(event.target.value)}
                  />
                </div>
                <div className="flex flex-col ml-2 mt-8">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 1,
                      backgroundColor: "white",
                      width: "6rem",
                      color: "#676767",
                    }}
                  >
                    EV Point Name
                  </span>
                  <input
                    className="w-full form-input border p-4 rounded-md"
                    style={{ borderColor: "#676767" }}
                    type="text"
                    value={name}
                    placeholder="e.g. InstaVolt22"
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                {/* <div className="flex flex-col ml-2 mt-8">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 1,
                      backgroundColor: "white",
                      width: "3.5rem",
                      color: "#676767",
                    }}
                  >
                    Lot Size
                  </span>
                  <input
                    className="w-full form-input border p-4 rounded-md"
                    style={{ borderColor: "#676767" }}
                    type="number"
                    value={investmentNeeded}
                    placeholder="e.g. 1000000"
                    onChange={(event) =>
                      setInvestmentNeeded(event.target.value)
                    }
                  />
                </div> */}
                <div className="flex flex-col ml-2 mt-8">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 1,
                      backgroundColor: "white",
                      width: "3.8rem",
                      color: "#676767",
                    }}
                  >
                    Location
                  </span>
                  <input
                    className="w-full form-input border p-4 rounded-md"
                    style={{ borderColor: "#676767" }}
                    type="text"
                    value={location}
                    placeholder="e.g. Cambridge Heath, Bethnal Green"
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </div>
                <div className="flex flex-col ml-2 mt-8">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 11,
                      backgroundColor: "white",
                      width: "12rem",
                      color: "#676767",
                    }}
                  >
                    Minimum Investment Amount
                  </span>
                  <input
                    className="w-full form-input border p-4 rounded-md"
                    style={{ borderColor: "#676767" }}
                    type="number"
                    value={investmentNeeded}
                    placeholder="e.g. 1000000"
                    onChange={(event) =>
                      setInvestmentNeeded(event.target.value)
                    }
                  />
                </div>
                {/* Payout Schedule is now immediate. Disabled the field for now. */}
                {/* <div className="flex flex-col ml-2 mt-8">
                  <span
                    className="text-sm ml-2"
                    style={{
                      marginBottom: "-0.65rem",
                      zIndex: 1,
                      backgroundColor: "white",
                      width: "7rem",
                      color: "#676767",
                    }}
                  >
                    Payout Schedule
                  </span>
                  <Select
                    className="w-full"
                    options={payoutOptions}
                    onChange={(newValue) => setPayout(newValue.value)}
                  />
                </div> */}
                <div className="flex flex-col ml-2 mt-14">
                  <button
                    type="button"
                    className={`w-full font-medium text-md p-4 rounded-full text-white`}
                    style={{ backgroundColor: "#007c92" }}
                    onClick={handleSubmit}
                    // disabled={isSubmit}
                  >
                    Create EV Point
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </>
      ) : (
        <SuccessScreen txHash={txHash} goBack={goToOrgLogin} text={'Go To Organisation Login'}/>
      )}
    </>
  );
};

// style={'width: 8rem; margin-bottom: -0.7rem; z-index: 1; background: white;color: #676767;'}

export default EditEv;
