/* eslint-disable react/style-prop-object */
import { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { LinearProgress, Typography } from "@material-ui/core";
import Select from "react-select";
import Chevron from "../../assets/Chevron.png";
import { useNavigate } from "react-router-dom";
import {
  Headers,
  contractAddress,
  tokenAddress,
} from "../../utils/constant";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import SuccessScreen from "../common/SuccessScreen";

const AddInvestment = () => {
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  let {
    selectedInvestment: investment,
    setSelectedInvestment,
    user,
    logout
  } = UserAuth();

  if (!investment) {
    investment = {
      name: "",
      company: "",
      location: "",
      funds_filled: 0,
      funds_left: 0,
      investors: 0,
      address: "",
      progress: 0,
      index: 1,
    };
  }

  useEffect(() => {
    setProgress(investment.progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment]);

  useEffect(() => {
    if (done) {
      navigate("/user/login");
      logout();
      setDone(false);
      setSelectedInvestment(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, navigate, setSelectedInvestment]);

  const goBack = () => {
    navigate("/org/dashboard");
  };

  const handleSubmit = async () => {
    if (!selectedAmount) {
      toast.error("Please select the amount");
      return;
    }
    setLoading(true);
    try {
      const abi = [
        `function invest(uint256 _kioskId, address _token, uint256 _amount)`,
      ];
      const PrivateUrl = new ethers.FetchRequest(
        "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
      );
      PrivateUrl.setHeader(
        "Authorization",
        "Basic " +
          Buffer.from(Headers.username + ":" + Headers.password).toString(
            "base64"
          )
      );
      const approvalAbi = [
        "function allowance(address owner, address spender) external view returns (uint256 remaining)",
        "function approve(address, uint256)",
      ];
      const provider = new ethers.JsonRpcProvider(PrivateUrl);
      const tokenContract = new ethers.Contract(
        tokenAddress,
        approvalAbi,
        provider
      );
      const allowance = ethers.formatEther(
        await tokenContract.allowance(user.address, contractAddress)
      );

      if (Number(selectedAmount) > Number(allowance)) {
        const approvalData = tokenContract.interface.encodeFunctionData(
          "approve",
          [contractAddress, ethers.parseEther(selectedAmount.toFixed(0))]
        );
        const approvalTxHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address, // The user's active address.
              to: tokenAddress, // Required except during contract publications.
              data: approvalData,
              gasPrice: 0,
            },
          ],
        });
        console.log("approval tx hash: ", approvalTxHash);
      }
      const contract = new ethers.Contract(contractAddress, abi, provider);
      console.log(
        "selectedAmount: ",
        selectedAmount,
        investment.index,
        tokenAddress
      );
      console.log(
        "params: ",
        investment.index,
        tokenAddress,
        ethers.parseEther(selectedAmount.toFixed(0))
      );
      const data = contract.interface.encodeFunctionData("invest", [
        investment.index,
        tokenAddress,
        ethers.parseEther(selectedAmount.toFixed(0)),
      ]);
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: user.address, // The user's active address.
            to: contractAddress, // Required except during contract publications.
            data: data,
            gasPrice: 0,
          },
        ],
      });
      console.log("txHash: ", txHash);
      setTxHash(txHash);
      setShowSuccess(true);
      toast.success("Transaction Done");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleInputChange = (newValue, event) => {
    setSelectedAmount(newValue.value);
  };

  const goToUserLogin = async () => {
    await logout();
    navigate("/user/login");
  }

  const minimumInvestmentValue = (investment?.maximumInvestment || 0) / 10;

  const options = [
    { value: minimumInvestmentValue, label: `£${minimumInvestmentValue} - Revenue Share 10%` },
    { value: minimumInvestmentValue * 2, label: `£${minimumInvestmentValue * 2} - Revenue Share 20%` },
    { value: minimumInvestmentValue * 3, label: `£${minimumInvestmentValue * 3} - Revenue Share 30%` },
  ];

  return (
    <>
      {!showSuccess ? (
        <div className="p-4 m-4">
          <div
            className="align-middle cursor-pointer flex flex-row mt-4 -ml-2"
            onClick={goBack}
          >
            <img src={Chevron} alt="Back" width={24} height={24} />
            <span className="text-md text-gray-600 mr-4">Back</span>
          </div>
          <div
            className="flex flex-row mt-4 justify-left"
            key={investment?.name ?? "Speechmark station 01"}
          >
            <div className="flex flex-col mt-3">
              <span className="text-md font-bold text-justify mb-2">
                {investment?.name}
              </span>
              <span className="text-sm text-justify">Your Investment: £0</span>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <div className="flex-1 mr-2">
              <LinearProgress
                style={{
                  height: "1rem",
                  borderRadius: "12px",
                  border: "1px solid darkgray",
                  backgroundColor: "#ffffff",
                  color: "#e0faff",
                }}
                className="rounded-2xl"
                variant="determinate"
                value={progress}
              />
            </div>
            <Typography variant="body2">{`${Math.round(
              progress
            )}%`}</Typography>
          </div>
          <div className="flex flex-row mt-4 justify-between">
            <div className="flex flex-col justify-between">
              <div>
                <span className="text-sm font-bold">Investors</span>
              </div>
              <div>
                <span className="text-xs">{investment.investors}</span>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <div>
                <span className="text-sm font-bold">Funds filled</span>
              </div>
              <div>
                <span className="text-xs">
                  £
                  {investment.funds_filled !== 0
                    ? investment.funds_filled
                    : investment.funds_filled}
                </span>
              </div>
            </div>
            <div className="flex flex-col ml-4 justify-center">
              <div>
                <span className="text-sm font-bold">Funds left</span>
              </div>
              <div>
                <span className="text-xs">
                  £
                  {investment.funds_left !== 0
                    ? investment.funds_left
                    : investment.funds_left}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex flex-col mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Company</span>
              </div>
              <div>
                <span className="text-xs">{investment.company}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex flex-col mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Location</span>
              </div>
              <div>
                <span className="text-xs">{investment.location}</span>
              </div>
            </div>
          </div>
          {/* Payout Schedule is now immediate. Disabled the field for now. */}
          {/* <div className="flex flex-row mt-4">
            <div className="flex flex-col mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Payout Schedule</span>
              </div>
              <div>
                <span className="text-xs">End of each week</span>
              </div>
            </div>
          </div> */}
          <div className="justify-center flex rounded-full mt-4 mb-8 w-96">
            <Select
              options={options}
              className="w-96"
              onChange={handleInputChange}
              defaultValue={100000}
            />
          </div>
          <button
            type="button"
            className={`w-96 font-medium text-md mt-8 px-3 py-3 rounded-full text-white ${
              loading ? "bg-gray-400" : "bg-cyan-600"
            }`}
            onClick={handleSubmit}
            disabled={loading}
          >
            Invest
          </button>
        </div>
      ) : (
        <SuccessScreen txHash={txHash} goBack={goToUserLogin} text={'Pay for EV charge'}/>
      )}
    </>
  );
};

export default AddInvestment;
