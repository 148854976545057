import { Card, LinearProgress, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Headers, contractAddress } from "../../utils/constant";
import Button from "../ButtonLoading";
import Shimmer from "../Shimmer";

const InvestmentCard = ({
  Invest,
  disabled,
  index
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(50);
  const [investment, setInvestment] = useState({
    name: "",
    company: "",
    location: "",
    funds_filled: 0,
    funds_left: 0,
    investors: 0,
    maximumInvestment: 0,
  });

  const getInvestment = async () => {
    setIsLoading(true);
    const deployedAbi = [
      "function returnCurrentInvestment(uint256 _id) public view returns (uint256)",
      "function returnMaximumInvestment(uint256 _id) public view returns (uint256)",
      "function returnKioskInvestors(uint256 _id) public view returns (address[])",
      "function balanceOf(address) public view returns (uint256)",
      `function returnKioskData(
        uint256 _id
    )
        external
        view
        returns (
            address kioskOwner,
            string memory kioskName,
            string memory kioskCompany,
            string memory kioskLocation,
            address energyProvider,
            address[] memory acceptedCurrencies,
            uint256 tranchePrice
        )`,
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    const deployedContract = new ethers.Contract(contractAddress, deployedAbi, provider);
    const totalSupply = Number(ethers.formatEther(
      await deployedContract.returnCurrentInvestment(index)
    )).toFixed(0);
    const maximumInvestment = ethers.formatEther(
      await deployedContract.returnMaximumInvestment(index)
    );
    console.log(maximumInvestment);
    const investors = (await deployedContract.returnKioskInvestors(index)).length;
    const kioskInformation = await deployedContract.returnKioskData(index);
    const investDetails = {
      name: kioskInformation[1],
      company: kioskInformation[2],
      location: kioskInformation[3],
      funds_filled: Number(totalSupply),
      funds_left: Number(maximumInvestment) - Number(totalSupply),
      investors: investors,
      index: index,
      maximumInvestment: Number(maximumInvestment),
      progress: (Number(totalSupply) / Number(maximumInvestment)) * 100,
    };
    console.log('investDetails: ', investDetails);
    setInvestment(investDetails);
    setProgress(investDetails.progress);
    setIsLoading(false);
  };

  useEffect(() => {
    getInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Shimmer />
      ) : (
        <Card
          className="mt-4 ml-4 mr-4 p-4"
          style={{
            backgroundImage:
              "linear-gradient(137deg, #fff 14%, rgba(212, 248, 255, 0.79) 95%)",
            borderRadius: "12px",
            boxShadow: "0 0 18px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <div className="justify-between flex flex-row">
              <div>
                <span className="text-sm font-bold">{investment.name}</span>
              </div>
              <div>
                <span className="text-sm font-bold text-blue-400">£{investment.maximumInvestment.toFixed(0)}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4 items-center">
            <div className="flex-1 mr-2">
              <LinearProgress
                style={{
                  height: "1rem",
                  borderRadius: "12px",
                  border: "1px solid darkgray",
                  backgroundColor: "#ffffff",
                  color: "#e0faff",
                }}
                className="rounded-2xl"
                variant="determinate"
                value={progress}
              />
            </div>
            <Typography variant="body2">{`${Math.round(progress,)}%`}</Typography>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex flex-col ml-4 mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Investors</span>
              </div>
              <div>
                <span className="text-xs">{investment.investors}</span>
              </div>
            </div>
            <div className="flex flex-col ml-4 mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Funds filled</span>
              </div>
              <div>
                <span className="text-xs">
                  £
                  {investment.funds_filled !== 0
                    ? investment.funds_filled
                    : investment.funds_filled}
                </span>
              </div>
            </div>
            <div className="flex flex-col ml-4 mr-4 justify-center">
              <div>
                <span className="text-sm font-bold">Funds left</span>
              </div>
              <div>
                <span className="text-xs">
                  £
                  {investment.funds_left !== 0
                    ? investment.funds_left
                    : investment.funds_left}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex flex-col ml-4 mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Location</span>
              </div>
              <div>
                <span className="text-xs">{investment.location}</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex flex-col ml-4 mr-8 justify-center">
              <div>
                <span className="text-sm font-bold">Company</span>
              </div>
              <div>
                <span className="text-xs">{investment.company}</span>
              </div>
            </div>
            <div className="flex ml-4 justify-end">
              <Button
                onClick={() => Invest(investment)}
                className={`${disabled ? 'w-44' : 'w-28'} font-medium text-md px-3 py-3 rounded-full text-white bg-cyan-600`}
                text={disabled ? "Already Invested" : "Invest"}
                disabled={disabled}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default InvestmentCard;
