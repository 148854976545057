import React from "react";
import VodafoneLogo from '../assets/vodafone_logo_white.png';

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-full"
      style={{ background: 'linear-gradient(191.55deg, #E60000 0%, #820000 116.75%)' }}>
      <img width='150px' src={VodafoneLogo} alt="vodafone logo" />
    </div>
  );
};

export default Loading;
