import { createContext, useContext, useState } from 'react';
// import { ethers } from 'ethers';
import { ROLE, UserAddresses } from '../utils/constant';
import { AdminAddresses, OrgAddresses } from '../utils/constant';
import WelcomeWrapper from '../components/WelcomeWrapper';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState();
  const [myInvestments, setMyInvestments] = useState([]);
  const [logType, setLogType] = useState();
  const [data, setData] = useState({
    address: "",
    Balance: null,
  });


  // const getBalance = async (address) => {

  //   // Requesting balance method
  //   const balance = await window.ethereum
  //     .request({
  //       method: "eth_getBalance",
  //       params: [address, "latest"]
  //     })
  //   setData({ address, Balance: ethers.formatEther(balance) })
  //   return balance;
  // };

  const initializeProvider = async () => {
    const res = await window.ethereum
      .request({ method: "eth_requestAccounts" });
    return res[0];
  }

  const accountChangeHandler = async (accounts) => {
    console.log('in here', accounts)
    const account = accounts[0];
    // Setting an address data
    setData({
      address: account,
    });
    setUser(null);
  };

  const filterAddr = async (logInType, address) => {
    console.log('logInType: ', logInType);
    if (!logInType) return false;
    if (logInType === ROLE.ORGANIZATION) {
      if (!OrgAddresses.includes(address)) {
        // toast.error('Invalid address', { position: 'top-center' });
        console.log('Invalid Address');
        return false;
      }
    } else if (logInType === ROLE.ADMIN) {
      if (!AdminAddresses.includes(address)) {
        // toast.error('Invalid address', { position: 'top-center' });
        console.log('Invalid Address provided', address)
        return false;
      }
    } else if (logInType === ROLE.USER) {
      if (!UserAddresses.includes(address)) {
        return false;
      }
    }
    return true;
  }

  const signIn = async (logInType) => {
    try {
      setLogType(logInType);
      if (!window.ethereum) return null;
      setIsSigningIn(true);
      const address = await initializeProvider();
      console.log('data: ', data, address);
      const isValid = await filterAddr(logInType, address);
      if (!isValid) return null;
      console.log('isValid: ', isValid);
      // const balance = await getBalance(address);
      setUser({ role: logInType, address });
      setIsSigningIn(false);
      return { role: logInType, address };
    } catch (error) {
      console.log('error', error);
    }
  };

  window.ethereum.on('accountsChanged', accountChangeHandler)

  const logout = async () => {
    if (user?.role === ROLE.ADMIN) {
      //
    }
    setLogType(null);
    setUser(null)
  };

  if (isSigningIn && user?.ROLE) {
    return <WelcomeWrapper logInType={user?.ROLE} />
  }

  return <UserContext.Provider value={{ user, logType, selectedInvestment, myInvestments, logout, signIn, setSelectedInvestment, setMyInvestments }}>{children}</UserContext.Provider>;
};

export const UserAuth = () => {
  return useContext(UserContext);
};
