import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { ROLE } from "../utils/constant";

const NotFound = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(!user?.accessToken) navigate('/admin/login');
    if (user?.role === ROLE.ORGANIZATION) {
      navigate('/org/dashboard');
    }
    if(user?.role === ROLE.ADMIN) {
      navigate('/admin/login');
    }
  }, [user, navigate]);

  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
