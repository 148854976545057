import React from "react";
import { Route, Routes } from 'react-router-dom';
import NotFound from './components/NotFound';
import SignIn from "./components/SignIn";
import { ROLE } from "./utils/constant";
import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from './components/ProtectedRoute';
import OrgDashboard from './components/org/Dashboard';
import AddInvestment from "./components/org/AddInvestment";
import EditEv from "./components/admin/EditEv";
import { Toaster } from "react-hot-toast";
import UserDashboard from "./components/user/Dashboard";

function App() {

  return (
    <>
      <Toaster />
      <div className='max-w-[450px] mx-auto border' style={{ height: '100vh', overflowY: 'auto', paddingBottom: '12px' }}>
      <AuthContextProvider>
        <Routes>
          {/* Sign In */}
          <Route path='/admin/login' element={<SignIn logInType={ROLE.ADMIN} />} />
          <Route path='/org/login' element={<SignIn logInType={ROLE.ORGANIZATION} />} />
          <Route path='/user/login' element={<SignIn logInType={ROLE.USER} />} />
          {/* Org Routes */}
          <Route
            path='/org/dashboard'
            element={
              <ProtectedRoute>
                <OrgDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/org/deposit'
            element={
              <ProtectedRoute>
                <AddInvestment />
              </ProtectedRoute>
            }
          />
          {/* Admin Routes */}
          <Route
            path='/admin/evsetup'
            element={
              <ProtectedRoute>
                <EditEv />
              </ProtectedRoute>
            }
          />
          {/* User Routes */}
          <Route
            path='/user/dashboard'
            element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AuthContextProvider>
    </div>
    </>
  );

}

export default App;