import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import SignInBackground from "../assets/signin_bg.png";
import VodafoneLogo from "../assets/vodafone_logo_white.png";
import { ROLE } from "../utils/constant";
import toast from "react-hot-toast";
import Loading from "./Loading";

const SignIn = ({ logInType }) => {
  const [isBGVisible, setIsBgVisible] = useState(false);
  const navigate = useNavigate();
  const { user, signIn } = UserAuth();
  const [isLogging, setIsLogging] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsBgVisible(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (user?.role === ROLE.ORGANIZATION) {
      navigate("/org/dashboard");
      // console.log('success')
    }
    if (user?.role === ROLE.ADMIN) {
      navigate("/admin/evsetup");
    }
    if (user?.role === ROLE.USER) {
      navigate("/user/dashboard");
    }
  }, [user, navigate]);

  const handleSubmit = async () => {
    try {
      setIsLogging(true);
      const retUser = await signIn(logInType);
      console.log("returned Value: ", retUser);
      if (!retUser) {
        if (logInType === ROLE.ADMIN) {
          toast.error(
            "Wallet address not supported. Please connect with an Administration wallet"
          );
        } else if (logInType === ROLE.ORGANIZATION) {
          toast.error(
            "Authentication failed - please connect with an Organisation wallet"
          );
        } else {
          toast.error("Invalid User");
        }
      } else toast.success("Logged in Successfully");
      setIsLogging(false);
    } catch (e) {
      console.log(e.message);
      setIsLogging(false);
    }
  };

  return (
    <>
      {isLogging ? (
        <Loading />
      ) : (
        <div
          className={"flex justify-center"}
          style={
            isBGVisible
              ? {
                  backgroundImage: `url(${SignInBackground})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  height: "100%",
                }
              : {
                  background:
                    "linear-gradient(191.55deg, #E60000 0%, #820000 116.75%)",
                  height: "100%",
                }
          }
        >
          <div className="flex flex-col justify-around items-center my-36">
            <div className="invisible"></div>
            <img width="150px" src={VodafoneLogo} alt="vodafone logo" />
            <button
              type="button"
              className={`${!isBGVisible && "invisible"} ${
                isLogging && "cursor-not-allowed"
              } w-96 font-medium text-sm rounded-full bg-white text-cyan-600 mt-16 px-6 py-4`}
              onClick={handleSubmit}
              disabled={isLogging}
            >
              {"Connect Wallet"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
