/* eslint-disable react/style-prop-object */
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  contractAddress,
  userTokenAddress,
  Headers,
} from "../../utils/constant";
import ChargerCard from "./ChargerCards";
import Shimmer from "../Shimmer";
import SuccessScreen from "../common/SuccessScreen";

const UserDashboard = () => {
  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  const [isInvesting, setIsInvesting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenBalance, setTokenBalance] = useState("0");
  const [deployedAddresses, setDeployedAddresses] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [txHash, setTxHash] = useState();
  const [amount, setAmount] = useState("10");

  const getTokenBalance = async () => {
    if (!user?.address) return;
    const tokenAbi = [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        type: "function",
      },
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    const tokenContract = new ethers.Contract(
      userTokenAddress,
      tokenAbi,
      provider
    );
    const balance = await tokenContract.balanceOf(user.address);
    setTokenBalance(ethers.formatEther(balance));
  };

  const fetchData = async () => {
    setIsLoading(true);
    let randomAmount = Math.random() * 100;
    if (randomAmount !== 0) {
      setAmount(randomAmount.toFixed(0));
    }
    const FactoryAbi = [
      {
        inputs: [],
        name: "kioskCount",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      "function kiosks(uint256) public view returns (address)",
      "function returnKioskList() public view returns (address[])",
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    getTokenBalance();
    const factoryContract = new ethers.Contract(
      contractAddress,
      FactoryAbi,
      provider
    );
    const list = await factoryContract.kioskCount();
    // const list_copy = [];
    // for (let i = 1; i <= list; i++) {
    //   list_copy.push(i);
    // }
    setDeployedAddresses([list]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Pay = async (index) => {
    try {
      const deployedAbi = [
        "function chargeEV(uint256 _kioskId, address _token, uint256 _amount)",
      ];
      const PrivateUrl = new ethers.FetchRequest(
        "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
      );
      PrivateUrl.setHeader(
        "Authorization",
        "Basic " +
          Buffer.from(Headers.username + ":" + Headers.password).toString(
            "base64"
          )
      );
      const approvalAbi = [
        "function allowance(address owner, address spender) external view returns (uint256 remaining)",
        "function approve(address, uint256)",
      ];
      const provider = new ethers.JsonRpcProvider(PrivateUrl);
      const tokenContract = new ethers.Contract(
        userTokenAddress,
        approvalAbi,
        provider
      );
      const allowance = ethers.formatEther(
        await tokenContract.allowance(user.address, contractAddress)
      );

      if (Number(amount) > Number(allowance)) {
        const approvalData = tokenContract.interface.encodeFunctionData(
          "approve",
          [contractAddress, ethers.parseEther(amount)]
        );
        const approvalTxHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [
            {
              from: user.address, // The user's active address.
              to: userTokenAddress, // Required except during contract publications.
              data: approvalData,
              gasPrice: 0,
            },
          ],
        });
        console.log("approval tx hash: ", approvalTxHash);
      }
      const deployedContract = new ethers.Contract(
        contractAddress,
        deployedAbi,
        provider
      );
      console.log("amount to be sent: ", amount);
      const data = deployedContract.interface.encodeFunctionData("chargeEV", [
        index,
        userTokenAddress,
        ethers.parseEther(amount),
      ]);
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: user.address, // The user's active address.
            to: contractAddress, // Required except during contract publications.
            data: data,
            gasPrice: 0,
          },
        ],
      });
      console.log("txHash: ", txHash);
      setTxHash(txHash);
      toast.success("Transaction Done");
      setShowSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = () => {
    setShowSuccess(false);
    getTokenBalance();
  };

  const handleLogout = async () => {
    try {
      navigate("/user/login");
      await logout();
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (isInvesting) {
      setIsInvesting(false);
    }
  }, [isInvesting, navigate]);

  const openMasterCardLink = (address) => {
    window.open(`https://explorer.mtn-stg.eap.kaleido.io/addresses/${address}`);
  };

  return (
    <>
      {!showSuccess ? (
        <>
          <div className="mt-4 mb-4 flex justify-center">
            <span
              className="text-xl font-bold text-gray-600 cursor-pointer items-center justify-center"
              onClick={handleLogout}
            >
              Pay for your charge
            </span>
          </div>
          <div className="flex flex-col justify-center items-center">
            <span
              className="text-sm text-gray-600 cursor-pointer"
              onClick={() => openMasterCardLink(user?.address)}
            >
              Logged in as: {user?.address}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center mt-4">
            <span className="text-lg font-bold text-gray-600 mr-2">
              £{(Number(tokenBalance)).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center mt-4">
            <span
              className="text-lg font-bold text-gray-600 mr-2 p-2 rounded-md border ring-1 ring-gray-400"
            >
              Payment due: £{(Number(amount)).toFixed(2)}
            </span>
          </div>
          {isLoading ? (
            <Shimmer />
          ) : (
            <div className="">
              {deployedAddresses.map((id, index) => (
                <ChargerCard index={id} key={index} Pay={Pay} />
              ))}
            </div>
          )}{" "}
        </>
      ) : (
        <SuccessScreen
          txHash={txHash}
          goBack={goBack}
        />
      )}
    </>
  );
};

export default UserDashboard;
