/* eslint-disable react/style-prop-object */
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { contractAddress, Headers, tokenAddress } from "../../utils/constant";
import Shimmer from "../Shimmer";
import InvestmentCard from "./InvestmentCards";

const OrgDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [deployedAddresses, setDeployedAddresses] = useState([]);
  const { user, setSelectedInvestment } = UserAuth();
  const [isInvesting, setIsInvesting] = useState(false);
  const [tokenBalance, setTokenBalance] = useState("0");
  const [myInvestments, setMyInvestments] = useState([]);
  const [investedIds, setInvestedIds] = useState([]);

  const getTokenBalance = async () => {
    const tokenAbi = [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256",
          },
        ],
        payable: false,
        type: "function",
      },
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);
    const balance = await tokenContract.balanceOf(user.address);
    setTokenBalance(Number(ethers.formatEther(balance)).toFixed(0));
  }

  useEffect(()=>{
    getTokenBalance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[myInvestments])

  const fetchData = async () => {
    setIsLoading(true);
    const FactoryAbi = [
      {
        inputs: [],
        name: "kioskCount",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      "function kiosks(uint256) public view returns (address)",
      `function returnKioskInvestorBalance(
        uint256 _id,
        address _investor
    ) public view returns (uint256)`,
      "function returnKioskInvestors(uint256 _id) public view returns (address[])",
      "function returnCurrentInvestment(uint256 _id) public view returns (uint256)",
      "function returnMaximumInvestment(uint256 _id) public view returns (uint256)",
      `function returnKioskData(
        uint256 _id
    )
        external
        view
        returns (
            address kioskOwner,
            string memory kioskName,
            string memory kioskCompany,
            string memory kioskLocation,
            address energyProvider,
            address[] memory acceptedCurrencies,
            uint256 tranchePrice
        )`,
      "function returnKioskInvestorBalance(uint256 _id,address _investor) public view returns (uint256)",
    ];
    const PrivateUrl = new ethers.FetchRequest(
      "https://u0bp2e7tzk-u0cpuj9soo-rpc.us0-aws.kaleido.io"
    );
    PrivateUrl.setHeader(
      "Authorization",
      "Basic " +
        Buffer.from(Headers.username + ":" + Headers.password).toString(
          "base64"
        )
    );
    const provider = new ethers.JsonRpcProvider(PrivateUrl);
    getTokenBalance();
    const factoryContract = new ethers.Contract(
      contractAddress,
      FactoryAbi,
      provider
    );
    const list = ethers.getBigInt(await factoryContract.kioskCount()).valueOf();
    console.log('deployedContracts: ', list);
    let list_copy = [];
    for (let i = 1; i <= list; i++) {
      list_copy.push(i);
      const investDetails = await factoryContract.returnKioskData(i);
      const maximumInvestment = ethers.formatEther(await factoryContract.returnMaximumInvestment(i));
      console.log('maximumInvestment: ', maximumInvestment);
      const balance = ethers.formatEther(
        await factoryContract.returnKioskInvestorBalance(i, user.address)
      );
      for (let j = 0;j<7; j++)
      console.log('investmentDetails: ', investDetails[j])
      console.log(balance);
      if (balance > 0) {
        const myInvestmentData = {
          name: investDetails[1],
          percent: (Number(balance) / Number(maximumInvestment)) * 100,
          imageUrl: "",
          balance: balance,
        };
        console.log("myInvestmentDetails: ", myInvestmentData);
        myInvestments.push(myInvestmentData);
        setMyInvestments(myInvestments);
        investedIds.push(i);
        setInvestedIds(investedIds);
      }
    }
    const reverseListCopy = list_copy.sort((a, b) => b-a);
    setDeployedAddresses(reverseListCopy);
    setIsLoading(false);
  };

  useEffect(() => {
    setInvestedIds([]);
    setMyInvestments([]);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInvesting) {
      setIsInvesting(false);
      navigate("/org/deposit");
    }
  }, [isInvesting, navigate]);

  const Invest = (investment) => {
    console.log(investment);
    setSelectedInvestment(investment ?? null);
    setIsInvesting(true);
  };

  const openMasterCardLink = (address) => {
    window.open(`https://explorer.mtn-stg.eap.kaleido.io/addresses/${address}`);
  };

  return (
    <>
      <div className="mt-4 ml-8 mb-4 flex justify-center">
        <span className="text-xl font-bold text-gray-600 items-center justify-center">
          Investment Dashboard
        </span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <span
          className="text-sm text-gray-600 cursor-pointer"
          onClick={() => openMasterCardLink(user?.address)}
        >
          Logged in as: {user?.address}
        </span>
      </div>

      <div className="flex flex-col justify-center items-center mt-4">
        <span className="text-lg font-bold text-gray-600 mr-2">
          £{tokenBalance}
        </span>
      </div>
      {isLoading ? (
        <Shimmer />
      ) : (
        <>
          <div className="">
            {deployedAddresses.map((id, index) => (
              <InvestmentCard
                key={index}
                index={id}
                disabled={investedIds.includes(id)}
                Invest={(element) => Invest(element)}
              />
            ))}
          </div>
          <div className="mt-6 ml-4">
            <span className="text-md">My Investments:</span>
          </div>
          <div>
            {myInvestments.length ? (
              myInvestments.map((investment, index) => {
                return (
                  <div
                    className="flex flex-row ml-4 mr-6 mt-4 justify-between items-center border rounded-md p-2"
                    key={index}
                  >
                    <div className="flex flex-row align-center">
                      <div className="flex flex-col ml-2">
                        <span className="text-sm font-bold text-justify mb-2">
                          {investment.name}
                        </span>
                        <span className="text-sm text-justify">
                          Investment: £
                          {Number(investment?.balance || "0.00").toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div> {investment.percent}% </div>
                  </div>
                );
              })
            ) : (
              <div className="p-4 ml-2">
                <span className="text-sm text-gray-600">
                  No Investments found
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrgDashboard;
