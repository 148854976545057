const Shimmer = () => {
  return <div className="rounded-md py-4 max-w-sm w-full mx-auto">
    <div className="animate-pulse justify-center items-center flex space-x-4">
      <div className="rounded-md bg-slate-200 h-10 w-16"></div>
      <div className="flex-1 space-y-3 py-1">
        <div className="h-4 bg-slate-200 rounded"></div>
        <div className="h-4 bg-slate-200 rounded"></div>
      </div>
    </div>
  </div>
}
export default Shimmer;