const Button = ({ isLoading, className, text, onClick, disabled }) => {
  return <button
    type="button"
    className={`flex justify-center ${className} ${(isLoading || disabled) && 'disabled:opacity-50 disabled:cursor-not-allowed'}`}
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    {isLoading && <span className="flex items-center">
      <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.96 7.96 0 0120 12h4c0-6.627-5.373-12-12-12v4c3.042 0 5.824 1.135 7.938 3l-2.647 3z"
        />
      </svg>
    </span>}
    {text}
  </button>
}

export default Button;