import tickImage from "../../assets/correct.png";

const SuccessScreen = ({ txHash, goBack, text }) => {

  return (
    <>
      <div style={{ top: "35%", left: "27%", position: "relative" }}>
        <img src={tickImage} width={"50%"} height={"50%"} alt="TickImage" />
      </div>
      <div
        style={{
          position: "relative",
          flexDirection: "column",
          display: "flex",
          top: "44%",
          alignItems: "center",
        }}
      >
        {
          text && <button
            type="button"
            className={`w-72 position-absolute bottom-0 font-medium text-md p-4 rounded-full text-white`}
            style={{ backgroundColor: "#007c92" }}
            onClick={goBack}
          >
            {text}
          </button>
        }
        <button
          type="button"
          className={`w-72 mt-4 font-medium text-md p-4 rounded-full text-white`}
          style={{ backgroundColor: "#007c92" }}
          onClick={() => window.open(`https://explorer.mtn-stg.eap.kaleido.io/transactions/${txHash}`, "_blank")}
        >
          View Transaction status
        </button>
      </div>
    </>
  );
};

export default SuccessScreen;
